<template>
  <!-- Error Page section -->
  <section class="error__section section--padding">
    <div class="container">
      <div class="error__inner text-center" >
        <img class="error__img" src="@/assets/img/other/404.png" alt="img">
        <h2 class="error__subtitle">哎呀...<span>你似乎迷路了！</span></h2>
        <p class="error__desc">你要找的页面不存在啦！可能被外星人搬走了，或者压根没来过这儿~ 🤔</p>
        <router-link class="error__btn solid__btn" :to="{name: 'home'}">返回首页</router-link>
      </div>
    </div>
  </section>
  <!-- Error Page section .\ -->
</template>

<script>
export default {
  name: 'NotFound'
};
</script>

<style scoped>
.not-found {
  text-align: center;
  margin-top: 50px;
}
</style>