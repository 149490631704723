import {createApp} from 'vue';
import App from './App.vue';
import router from './router';

import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// 导入所需的图标
import {fas} from '@fortawesome/free-solid-svg-icons'; // 实心图标
import {far} from '@fortawesome/free-regular-svg-icons'; // 常规（空心）图标
import {fab} from '@fortawesome/free-brands-svg-icons'; // 品牌图标

// 将图标库添加到 Font Awesome
library.add(fas, far, fab);
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(ElementPlus);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(router).mount('#app');